<template>
  <v-row
    v-if="currentStep < 3"
    no-gutters
  >
    <v-col>
      <v-skeleton-loader
        :loading="loadingStepper"
        :style="{ height: '100%', width: '100%' }"
        type="card-heading, image@3"
      >
        <component
          :is="stepperComponent.name"
          v-bind="{...stepperComponent.props}"
          v-on="{...stepperComponent.on}"
        >
          <v-row
            justify="center"
            class="row__master-stepper"
            style="margin-top: 34px;"
            no-gutters
          >
            <v-col
              :cols="12"
              :sm="12"
              :md="11"
              :lg="10"
            >
              <v-tabs-items
                v-model="currentStep"
              >
                <v-tab-item
                  :value="0"
                >
                  <main-step
                    ref="stepMain"
                    :stepper-data.sync="stepperData"
                    @continue="currentStep = 1"
                    @save="savePromo"
                  />
                </v-tab-item>
                <v-tab-item
                  :value="1"
                >
                  <assortment-step
                    ref="stepAssortment"
                    :stepper-data.sync="stepperData"
                    @continue="currentStep = 2"
                    @save="savePromo"
                  />
                </v-tab-item>
                <v-tab-item
                  :value="2"
                >
                  <work-rules-step
                    ref="stepWorkRules"
                    :stepper-data.sync="stepperData"
                    @continue="savePromo"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </component>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { deepClone } from '@/utils'

  export default {
    components: {
      MainStep: () => import('./components/steps/MainStep.vue'),
      AssortmentStep: () => import('./components/steps/AssortmentStep.vue'),
      WorkRulesStep: () => import('./components/steps/WorkRulesStep.vue'),
    },
    props: {
      promoId: {
        type: [Number, String],
        default: NaN,
      },
      copyId: {
        type: [Number, String],
        default: NaN
      },
      startPage: {
        type: String,
        default: '#main',
      },
    },
    provide () {
      return {
        isEdit: this.isEdit,
        isCopy: this.isCopy
      }
    },
    data () {
      return {
        currentStep: 0,
        loadingStepper: false,
        stepperList: [
          { title: 'Общие параметры' },
          { title: 'Ассортимент' },
          { title: 'Правила работы' },
        ],
        pageList: [
          { id: '#main', name: 'Общие параметры', route: { hash: '#main' } },
          { id: '#assortment', name: 'Отбор ассортимент', route: { hash: '#assortment' } },
          { id: '#work-rules', name: 'Правила работы', route: { hash: '#work-rules' } },
        ],
        stepperData: {
          name: '',
          start_at: null,
          finish_at: null,
          all_nomenclature: false,
          filter_nomenclature_segment_id: null,
          receipt_nomenclature_segment_id: null,
          receipt_filter: null,
          all_shops: true,
          program_shop_ids: [],
          null_category_selected: false,
          offer_price_percent: null,
          nomenclature_category_uuids: [],
          nomenclature_uuids: [],
          program_bonus_res: [],
          priority: 1,
          client_filter : {type : "ALL"}
        },
      }
    },
    computed: {
      ...mapGetters({
        offerInfo: 'offers/offers/offerInfo',
        programId: 'programId',
      }),
      offers () {
        return this.$store.getters['offers/offers/offers']
      },
      isEdit () {
        return this.promoId && !isNaN(this.promoId)
      },
      isCopy () {
        return this.copyId && !isNaN(this.copyId)
      },
      stepperComponent () {
        if (this.isEdit) {
          return {
            name: 'base-top-menu',
            props: {
              menu: this.pageList,
              cancelButtonText: 'Закрыть',
              value: this.currentStep,
            },
            on: {
              cancelbutton: () => {
                this.$router.push({ name: 'PromoList' })
              },
              actionbutton: () => {
                this.savePromo()
              },
              change: v => {
                this.currentStep = v
              },
            },
          }
        } else {
          return {
            name: 'base-stepper',
            props: {
              value: this.currentStep,
              items: this.stepperList,
            },
            on: {
              clickBack: () => {
                this.clickedBack()
              },
              change: v => {
                this.currentStep = v
              },
            },
          }
        }
      },
    },
    watch: {

    },
    async mounted () {
      if (this.isEdit) {
        await this.setEditStepperData()
      } else if (this.isCopy) {
          await this.setCopyStepperData()
          this.stepperData.name += ' (копия)'
      } else {
          this.stepperData.program_id = this.programId
      }
    },
    methods: {
      ...mapActions({
        getOffer: 'offers/offers/getInfo',
      }),
      setItemById (id) {
        const index = this.pageList.findIndex(x => x.id === id)
        this.currentStep = (index >= 0 ? index : 0)
      },
      async savePromo () {
        /*if (this.stepperData.client_filter.segment_id_list && this.stepperData.client_filter.segment_id_list[0] && this.stepperData.client_filter.segment_id_list[0].id)
          this.stepperData.client_filter.segment_id_list.forEach((el, index) => {
            this.stepperData.client_filter.segment_id_list[index] = el.id
          })*/
        try {
          console.log("SAVE PROMO", this.stepperData)
          if (this.stepperData.client_filter.segment_id_list?.length > 0) {
            this.stepperData.client_filter.segment_id_list = this.stepperData.client_filter.segment_id_list.map(function (element) {
              if (typeof element == "object") { return element.id }
              else { return element }
            });
          }
          if (!this.isEdit) {
            await this.$store.dispatch('offers/offers/create', this.stepperData)
            this.$notify({
              title: 'Акция',
              text: 'Акция создана',
              type: 'success',
            })
          } else {
            await this.$store.dispatch('offers/offers/update', { offerId: this.promoId, payload: this.stepperData })
            this.$notify({
              title: 'Акция',
              text: 'Акция сохранена',
              type: 'success',
            })
          }
          this.$router.push({ name: 'PromoList' })
        } catch (err) {
          console.log(err)
        }
      },
      async setEditStepperData () {
        await this.setStepperData(this.promoId)
      },
      async setCopyStepperData () {
        await this.setStepperData(this.copyId)
      },
      async setStepperData(id) {
        this.setItemById(this.startPage)
        this.loadingStepper = true
        await this.getOffer(id)
        const offerInfo = deepClone(this.offerInfo)

        offerInfo.program_bonus_res = offerInfo.program_bonus_res
            .map(pbr => {
              pbr.percent = pbr.pivot.percent
              pbr.rules.newPercent = pbr.pivot.percent
              return pbr
            })

        this.stepperData = offerInfo
        console.log('stepperData', this.stepperData)
        this.loadingStepper = false
      },
      clickedBack () {
        if (this.currentStep > 0) {
          this.currentStep -= 1
        } else {
          this.$router.back()
        }
      },
    },
  }
</script>

<style lang="scss">
.row__master-stepper {
  .v-window {
    overflow: visible;
  }
  .v-btn {
    p {
      margin-left: 8px;
    }
  }
}
.master-next-btn {
  margin-top: 34px;
  margin-bottom: 68px;
  p {
    margin-right: 8px;
  }
}
</style>
